import Vue from 'vue'

const hasSizeProperty = (type) => {
	switch (Number(type)) {
		case 6:
		case 7:
			return true
		default:
			return false
	}
}
//获取门店列表
const getStoreList = (data, province) => {
	let dto = {
		pageNum: 1,
		pageSize: 99999,
	};
	return new Promise((resolve, reject) => {
		Vue.prototype.post("/mall-service/counter_group/get_list",dto,{
			isUseResponse: true
		}).then(res => {
			let storeList = res.data.data.records;
			storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
			storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
			resolve(storeList);
		}).catch(err => {
			reject(err);
		});
	})

}

const getContainerModel = () => {
	let list = [{
			label: 'WF-L2K',
			value: 1,
		},
		{
			label: 'WF-L21',
			value: 2,
		},
		{
			label: 'WF-L22',
			value: 3,
		},
		{
			label: 'WF-L21BD',
			value: 4,
		}, {
			label: 'WF-L21BX',
			value: 5,
		}, {
			label: 'WF-L22BD',
			value: 6,
		}, {
			label: 'WF-L22BX',
			value: 7,
		}, {
			label: 'WF-L25',
			value: 8,
		}, {
			label: 'WF-L25A',
			value: 9,
		}, {
			label: 'WF-L25B',
			value: 10,
		}, {
			label: 'WF-L25C',
			value: 11,
		}, {
			label: "WF-L25E",
			value: 2004,
		},{
			label: "WF-L25F",
			value: 2005,
		},{
			label: 'WF-L25AF',
			value: 12,
		}, {
			label: 'WF-L25CF',
			value: 13,
		}, {
			label: 'WF-L25AK',
			value: 14,
		}, {
			label: 'WF-L25CK',
			value: 15,
		}, {
			label: 'WF-L25BK',
			value: 16,
		}, {
			label: 'WF-L25MK',
			value: 2007,
		},{
			label: 'WF-L25BMK',
			value: 2013,
		}, {
			label: 'WF-L25-6',
			value: 2009,
		},{
			label: 'WF-L25-12',
			value: 2010,
		},{
			label: 'WF-L25-18',
			value: 2011,
		},{
			label: 'WF-L25-24',
			value: 2012,
		}, {
			label: 'WF-L25D',
			value: 18,
		}, {
			label: 'WF-L25AB',
			value: 19,
		}, {
			label: 'WF-L25E85',
			value: 2008,
		}, {
			label: 'WF-T160',
			value: 20,
		},{
			label: 'WF-T254',
			value: 21,
		},{
			label: 'WF-T260',
			value: 2014,
		},{
			label: 'WF-L27',
			value: 2001,
		},{
			label: 'WF-L27N',
			value: 2006,
		},{
			label: 'WF-L25AM',
			value: 2016,
		},{
			label: 'WF-L25-24H',
			value: 4049,
		},
	];
	return list;
}

const getContainerTypeName = (item) => {
	let counterTypeStr = '';
	if ([6, 7,2001,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2016,4049].indexOf(item.counterType) != -1) {
		if (item.counterType == 6) {
			if (item.size == 1) {
				counterTypeStr = 'WF-L21BD';
			} else if (item.size == 2) {
				counterTypeStr = 'WF-L21BX';
			}
		}
		if (item.counterType == 7) {
			if (item.size == 1) {
				counterTypeStr = 'WF-L22BD';
			} else if (item.size == 2) {
				counterTypeStr = 'WF-L22BX';
			}
		}
		if (item.counterType == 2001) {
			counterTypeStr = 'WF-L27';
		}
		if (item.counterType == 2004) {
			counterTypeStr = 'WF-L25E';
		}
		if (item.counterType == 2005) {
			counterTypeStr = 'WF-L25F';
		}
		if (item.counterType == 2006) {
			counterTypeStr = 'WF-L27N';
		}
		if (item.counterType == 2007) {
			counterTypeStr = 'WF-L25MK';
		}
		if (item.counterType == 2008) {
			counterTypeStr = 'WF-L25E85';
		}
		if (item.counterType == 2009) {
			counterTypeStr = 'WF-L25-6';
		}
		if (item.counterType == 2010) {
			counterTypeStr = 'WF-L25-12';
		}
		if (item.counterType == 2011) {
			counterTypeStr = 'WF-L25-18';
		}
		if (item.counterType == 2012) {
			counterTypeStr = 'WF-L25-24';
		}
		if (item.counterType == 2013) {
			counterTypeStr = 'WF-L25BMK';
		}
		if (item.counterType == 2014) {
			counterTypeStr = 'WF-T260';
		}
		if (item.counterType == 2016) {
			counterTypeStr = 'WF-L25AM';
		}
		if (item.counterType == 4049) {
			counterTypeStr = 'WF-L25-24H';
		}
	} else {
		counterTypeStr = 'WF-' + ['L2K', 'L21', 'L22', '', '', '', '', 'L25', 'L25A', 'L25B', 'L25C', 'L25AF', 'L25CF', 'L25AK', 'L25CK','L25BK','L25-18','L25D','L25AB','T160','T254'][item.counterType - 1]
	}
	return counterTypeStr
}

const upgrade = (row) => {
	let dto = {
		mac: row.mac,
		id: row.id,
		counterType: row.type,
		size: row.size,
	}
	Vue.prototype.post('mall-service/v1/mall/firmware/upgrade', dto, {
		isUseResponse: true,
	}).then(res => {
		return res
	}).catch(err => {
		return err;
	})
}

/**
 * 获取门店列表
 * @param {*} userId 
 * @param {门店名称，用于模糊查询} name 
 * @returns 
 */
const queryStores = (userId, name, city) => {
	return new Promise((resolve, reject) => {
		let dto = {}
		if (name || city) {
			if(name){
				dto.keyName = name
			}
			if(city){
				dto.province = city[0],
				dto.city = city[1]
			}
		} else {
			if (userId && userId != 0) {
				dto.userId = userId
			}
		}
		Vue.prototype.post('mall-service/v1/counter/auth/all/list', dto, {
			isUseResponse: true,
		}).then(res => {
			if (res.data.code == 0) {
				if(res.data.data.authCounterList.length > 0){
					res.data.data.authCounterList.forEach(item =>{
						if(item.chargeName&&item.chargePhone){
							item.shopName = item.shopName + '(' + item.chargeName + '/' + item.chargePhone + ')'
						}else{
							item.shopName = item.shopName
						}
					})
				}
				resolve(res.data.data)
			} else {
				resolve("查询失败")
			}
		}).catch(err => {
			resolve(err)
		})
	})
}

/**
 * 给工程人员授权货柜
 * @param {*} dto 
 * @returns 
 */
const requstCounterAuth = (dto) => {
	return new Promise((resolve, reject) => {
		Vue.prototype.post('mall-service/v1/counter/auth', dto, {
			isUseResponse: true,
		}).then(res => {
			if (res.data.code == 0) {
				resolve()
			} else {
				resolve(res.data.msg || '授权失败')
			}
		}).catch(err => {
			resolve('授权失败')
		})
	})
}
/**
 * 房源数据
 * @param {*} dto 
 * @returns 
 */
const queryHouses = () => {
	return new Promise((resolve, reject) => {
		let dto = {}
		Vue.prototype.post('landlord-service/house/v1/device/get-house-info', dto, {
			isUseResponse: true,
		}).then(res => {
			if (res.data.code == 0) {
				res.data.data = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, "houseId"));
				resolve(res.data.data)
			} else {
				resolve("查询失败")
			}
		}).catch(err => {
			resolve(err)
		})
	})
}
//网关数据
const queryGatewayById = (val1,val2) => {
	return new Promise((resolve,reject) => {
		let dto = {
			houseId: val1.houseId,
			userId: val2
		}
		Vue.prototype.post('landlord-service/house/v1/auth/get-gateway-info', dto, {
			isUseResponse: true,
		}).then(res => {
			if (res.data.code == 0) {
				res.data.data = JSON.parse(JSON.stringify(res.data.data).replace(/isSelect/g, "isAuth"));
				resolve(res.data.data)
			} else {
				resolve("查询失败")
			}
		}).catch(err => {
			resolve(err)
		})
	})
	
}

/**
 * 给工程人员授权网关
 * @param {*} dto 
 * @returns 
 */
const requstGatewayAuth = (dto) => {
	return new Promise((resolve, reject) => {
		Vue.prototype.post('landlord-service/house/v1/auth/auth', dto, {
			isUseResponse: true,
		}).then(res => {
			if (res.data.code == 0) {
				resolve()
			} else {
				resolve(res.data.msg || '授权失败')
			}
		}).catch(err => {
			resolve('授权失败')
		})
	})
}


const getMerchantList = (dto) => {
	dto = dto ? dto : {roleType: 1,}
	return new Promise((resolve, reject) => {
		Vue.prototype.post('/mall-service/merchant/v1/query/list',dto).then(res => {
			res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
			res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
			resolve(res);
		  }).catch(err => {
			  reject(err);
		  })
	})
}

//货柜类型
const all = {
  L2K: {
    value: 1,
    name: 'L2K',
    model: 'WF-L2K',
    intro: '电信卡售卖机',
    multi: false,
  },
  L21: {
    value: 2,
    name: 'L21',
    model: 'WF-L21',
    intro: '单柜门智能售卖机',
    multi: false,
  },
  L22: {
    value: 3,
    name: 'L22',
    model: 'WF-L22',
    intro: '智能售卖机',
    multi: true,
  },
  L21B: {
    value: 4,
    name: 'L21B',
    model: 'WF-L21B',
    intro: '单柜门智能售卖机',
    multi: false,
  },
  L22B: {
    value: 5,
    name: 'L22B',
    model: 'WF-L22B',
    intro: '智能售卖机',
    multi: true,
  },
  L21BD: {
    value: 6,
    name: 'L21BD',
    model: 'WF-L21BD',
    intro: '单柜门智能柜（大）',
    multi: false,
    size: -1,
  },
  L22BD: {
    value: 7,
    name: 'L22BD',
    model: 'WF-L22BD',
    intro: '多柜门智能柜（大）',
    multi: true,
    size: -1,
  },
  L25: {
    value: 8,
    name: 'L25',
    model: 'WF-L25',
    intro: '多柜门售货柜',
    multi: true,
    size: -1,
  },
  L25A: {
    value: 9,
    name: 'L25A',
    model: 'WF-L25A',
    intro: '多柜门存货柜',
    multi: true,
    size: -1,
  },
  L25B: {
    value: 10,
    name: 'L25B',
    model: 'WF-L25B',
    intro: '多柜门售货柜',
    multi: true,
    size: -1,
  },
  L25C: {
    value: 11,
    name: 'L25C',
    model: 'WF-L25C',
    intro: '多柜门存货柜',
    multi: true,
    size: -1,
  },
  L25AF: {
    value: 12,
    name: 'L25AF',
    model: 'WF-L25AF',
    intro: '人脸储物柜',
    multi: true,
    size: -1,
  },
  L25CF: {
    value: 13,
    name: 'L25CF',
    model: 'WF-L25CF',
    intro: '人脸储物柜',
    multi: true,
    size: -1,
  },
  L25AK: {
    value: 14,
    name: 'L25AK',
    model: 'WF-L25AK',
    intro: '刷卡储物柜',
    multi: true,
    size: -1,
  },
  L25CK: {
    value: 15,
    name: 'L25CK',
    model: 'WF-L25CK',
    intro: '刷卡储物柜',
    multi: true,
    size: -1,
  },
  L25BK: {
    value: 16,
    name: 'L25BK',
    model: 'WF-L25BK',
    intro: '刷卡购物柜',
    multi: true,
    size: -1,
  },
  L25_18: { //同L25
    value: 17,
    name: 'L2518',
    model: 'WF-L25-18',
    intro: '多柜门售货柜',
    multi: true,
    size: -1,
  },
  L25D: { //同L25
    value: 18,
    name: 'L25D',
    model: 'WF-L25D',
    intro: '多柜门售货柜',
    multi: true,
    size: -1,
  },
}

const getCabinetBySn = (sn) => {
	if (!sn) {
	  return null
	}
  
	sn = sn.replace(/\ +/g, '')
	sn = sn.replace(/[\r\n]/g, '')
  
	if (sn.length != 24 && sn.length != 25 && sn.length != 26 && sn.length != 34) {
	  return null
	}
	let model3 = sn.slice(0, 3)
	let model4 = sn.slice(0, 4)
	let model5 = sn.slice(0, 5)
	let deviceMac = sn.replace(/:/g, '').slice(-12).toUpperCase()
  
	let getL25MacFunc = () => {
	  return sn.replace(/:/g, '').slice(-12).toUpperCase()
	}
  
	let getMacBy20 = () => {
	  return sn.replace(/:/g, '').slice(-20).toUpperCase()
	}
  
	if (all.L2K.name == model3) {
	  return {
		...all.L2K,
		mac: deviceMac,
		sn: sn
	  }
	}
	if (all.L25.name == model3 && sn.length == 24) {
	  return {
		...all.L25,
		mac: getL25MacFunc(),
		sn: sn
	  }
	}
	if (sn.length == 26 || sn.length == 34) {
	  if (all.L25AF.name == model5) {
		return {
		  ...all.L25AF,
		  mac: sn.length == 34 ? getMacBy20() : getL25MacFunc(),
		  sn: sn
		}
	  } else if (all.L25CF.name == model5) {
		return {
		  ...all.L25CF,
		  mac: sn.length == 34 ? getMacBy20() : getL25MacFunc(),
		  sn: sn
		}
	  } else if (all.L25AK.name == model5) {
		return {
		  ...all.L25AK,
		  mac: getL25MacFunc(),
		  sn: sn
		}
	  } else if (all.L25CK.name == model5) {
		return {
		  ...all.L25CK,
		  mac: getL25MacFunc(),
		  sn: sn
		}
	  } else if (all.L25BK.name == model5) {
		return {
		  ...all.L25BK,
		  mac: getL25MacFunc(),
		  sn: sn
		}
	  } else if (all.L25_18.name == model5) {
		return {
		  ...all.L25_18,
		  mac: getL25MacFunc(),
		  sn: sn
		}
	  }
	}
	if (sn.length == 25) {
	  if (all.L25A.name == model4) {
		return {
		  ...all.L25A,
		  mac: getL25MacFunc(),
		  sn: sn
		}
	  } else if (all.L25B.name == model4) {
		return {
		  ...all.L25B,
		  mac: getL25MacFunc(),
		  sn: sn
		}
	  } else if (all.L25C.name == model4) {
		return {
		  ...all.L25C,
		  mac: getL25MacFunc(),
		  sn: sn
		}
	  } else if (all.L25D.name == model4) {
		return {
		  ...all.L25D,
		  mac: getL25MacFunc(),
		  sn: sn
		}
	  }
	}
  
	if (all.L21BD.name == model5) {
	  return {
		...all.L21BD,
		size: 1,
		mac: deviceMac,
		sn: sn,
		doorCount: 0
	  }
	} else if (all.L21B.name == model4) {
	  // return {
	  //   ...all.L21B,
	  //   mac: deviceMac,
	  //   sn: sn,
	  //   doorCount: 0
	  // }
	  return null
	} else if (all.L21.name == model3) {
	  return {
		...all.L21,
		sn: sn,
		mac: deviceMac,
	  }
	}
  
	if (all.L22B.name == model4) {
	  // return {
	  //   ...all.L22B,
	  //   mac: deviceMac,
	  //   sn: sn,
	  //   doorCount: 0
	  // }
	  return null
	} else if (all.L22.name == model3) {
	  return {
		...all.L22,
		mac: deviceMac,
		sn: sn
	  }
	}
  
	return null
  }

// 元转分 - 解决精度问题 yuan:要转换的钱，单位元; digit：转换倍数 两个入参都是Number类型
const regYuanToFen = (yuan, digit) => {
	var m = 0,
		s1 = yuan.toString(),
		s2 = digit.toString();
	try {
		m += s1.split(".")[1].length
	} catch (e) {}
	try {
		m += s2.split(".")[1].length
	} catch (e) {}
	return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}

export default {
	hasSizeProperty,
	getStoreList,
	getContainerModel,
	getContainerTypeName,
	upgrade,
	queryStores,
	requstCounterAuth,
	getMerchantList,
	getCabinetBySn,
	regYuanToFen,
	requstGatewayAuth,
	queryHouses,
	queryGatewayById,
}